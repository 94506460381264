import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

// Aquí importo la vista a utilizar (Se puede seguir en la carpeta views)
import Home from '../views/Home.vue'

import Projuventud from '../views/Projuventud.vue'

import Presup_juventud from '../views/Presup_juventud.vue'
import Aten_ciudadana from '../views/Atencion_ciudadana.vue'

import Intervenciones from '../views/Intervenciones.vue'
import Metas from '../views/Metas.vue'
import Acciones from '../views/Acciones.vue'
import Doctos from '../views/Doctos.vue'
import Sectoriales from '../views/Sectoriales.vue'    
import Avance from '../views/Avance.vue'
import Login from '../views/Login.vue'
import Logout from '../views/Logout.vue'
import UserAdmin from '../views/UserAdmin.vue'

import ActionsAdmin from '../views/ActionsAdmin.vue'
import AdminSec from '../views/AdminSec.vue'


import MesAdmin from '../views/MesAdmin.vue'
import ActionsMesesAdmin from '../views/ActionsMesesAdmin.vue'

import ObjetivoAdmin from '../views/ObjetivoAdmin.vue'
import ActionsObjetivosAdmin from '../views/ActionsObjetivosAdmin.vue'

import DependenciaAdmin from '../views/DependenciaAdmin.vue'
import ActionsDependenciasAdmin from '../views/ActionsDependenciasAdmin.vue'

import EstadoAdmin from '../views/EstadoAdmin.vue'
import ActionsEstadosAdmin from '../views/ActionsEstadosAdmin.vue'

import NivelAdmin from '../views/NivelAdmin.vue'
import ActionsNivelesAdmin from '../views/ActionsNivelesAdmin.vue'

import PeriodicidadAdmin from '../views/PeriodicidadAdmin.vue'
import ActionsPeriodicidadesAdmin from '../views/ActionsPeriodicidadesAdmin.vue'

import FormulaAdmin from '../views/FormulaAdmin.vue'
import ActionsFormulasAdmin from '../views/ActionsFormulasAdmin.vue'

import AnioAdmin from '../views/AnioAdmin.vue'
import ActionsAniosAdmin from '../views/ActionsAniosAdmin.vue'

import Unidad_medidaAdmin from '../views/Unidad_medidaAdmin.vue'
import ActionsUnidades_medidaAdmin from '../views/ActionsUnidades_medidaAdmin.vue'

import Clase_indAdmin from '../views/Clase_indAdmin.vue'
import ActionsClases_indAdmin from '../views/ActionsClases_indAdmin.vue'

import Dimension_indicadorAdmin from '../views/Dimension_indicadorAdmin.vue'
import ActionsDimensiones_indicadorAdmin from '../views/ActionsDimensiones_indicadorAdmin.vue'

import Periodo_recoleccionAdmin from '../views/Periodo_recoleccionAdmin.vue'
import ActionsPeriodos_recoleccionAdmin from '../views/ActionsPeriodos_recoleccionAdmin.vue'

import Tipo_calculoAdmin from '../views/Tipo_calculoAdmin.vue'
import ActionsTipos_calculoAdmin from '../views/ActionsTipos_calculoAdmin.vue'

import Tendencia_indicadorAdmin from '../views/Tendencia_indicadorAdmin.vue'
import ActionsTendencias_indicadorAdmin from '../views/ActionsTendencias_indicadorAdmin.vue'

import Tipo_apAdmin from '../views/Tipo_apAdmin.vue'
import ActionsTipos_apAdmin from '../views/ActionsTipos_apAdmin.vue'

import Tipo_indicadorAdmin from '../views/Tipo_indicadorAdmin.vue'
import ActionsTipos_indicadorAdmin from '../views/ActionsTipos_indicadorAdmin.vue'

import EstrategiaAdmin from '../views/EstrategiaAdmin.vue'
import ActionsEstrategiasAdmin from '../views/ActionsEstrategiasAdmin.vue'

import ActionsBienvenidasAdmin from '../views/ActionsBienvenidasAdmin.vue'
import ActionsParam_genAdmin from '../views/ActionsParam_genAdmin.vue'

import ActionsProjuventudesAdmin from '../views/ActionsProjuventudesAdmin.vue'
import ActionsPresupuestosAdmin from '../views/ActionsPresupuestosAdmin.vue'
import ActionsIntervencionesAdm from '../views/ActionsIntervencionesAdm.vue'

import Meta_fichaAdmin from '../views/Meta_fichaAdmin.vue'
import ActionsMetas_fichaAdmin from '../views/ActionsMetas_fichaAdmin.vue'

import Accion_puntualAdmin from '../views/Accion_puntualAdmin.vue'
import ActionsAccions_puntualAdmin from '../views/ActionsAccions_puntualAdmin.vue'

import Accion_sectorialAdmin from '../views/Accion_sectorialAdmin.vue'
import ActionsAcciones_sectorialAdmin from '../views/ActionsAcciones_sectorialAdmin.vue'

import InformeAdmin from '../views/InformeAdmin.vue'
import ActionsInformesAdmin from '../views/ActionsInformesAdmin.vue'

import Compromiso_depAdmin from '../views/Compromiso_depAdmin.vue'
import ActionsCompromisos_depAdmin from '../views/ActionsCompromisos_depAdmin.vue'

import DescargableAdmin from '../views/DescargableAdmin.vue'
import ActionsDescargablesAdmin from '../views/ActionsDescargablesAdmin.vue'

import Recover from '../views/Recover.vue'
import CambioPassword from '../views/CambioPassword.vue'

import IntervencionesAdmin from '../views/IntervencionesAdmin.vue'
import ActionsIntervencionesAdmin from '../views/ActionsIntervencionesAdmin.vue'
import IntervencionesOrder from '../views/IntervencionesOrder.vue'
import ActionsIntervencionesOrder from '../views/ActionsIntervencionesOrder.vue'

import Accion_objetivo_avAdmin from '../views/Accion_objetivo_avAdmin.vue'
import ActionsAccions_objetivo_avAdmin from '../views/ActionsAccions_objetivo_avAdmin.vue'



// Se genera el arreglo con las rutas permitidas
const routes: Array<RouteRecordRaw> = [
  // Inicio
  {
    path: '/',
    name: 'Inicio',
    component: Home
  },

  // Projuventud
  {
    path: '/projuventud/',
    redirect: '/projuventud/quees'
  },
  {
    path: '/projuventud/:mod',
    name: 'Projuventud',
    component: Projuventud
  },

  
  {
    path: '/metas',
    name: 'Metas',
    component: Metas
  },
  {
    path: '/acciones',
    name: 'Acciones',
    component: Acciones
  },
  
  
  {
    path: '/presup_juventud',
    name: 'Presup_juventud',
    component: Presup_juventud
  },

  {
    path: '/aten_ciudadana',
    name: 'Aten_ciudadana',
    component: Aten_ciudadana
  },

  {
    path: '/intervenciones/',
    redirect: '/intervenciones/interv_reg'
  },
  {
    path: '/intervenciones/',
    redirect: '/intervenciones/ver'
  },
  {
    path: '/intervenciones/:mod',
    name: 'Intervenciones',
    component: Intervenciones
  },

  // Calculdaora - Cuestionario
  {
    path: '/calculadora/',
    redirect: '/calculadora/calcuestionario'
  },
  
  {
    path: '/doctos',
    name: 'Documentos',
    component: Doctos
  },

  {
    path: '/sectoriales',           
    name: 'Sectoriales',
    component: Sectoriales
  },

  {
    path: '/avance/:tipo/:event/:id',
    name: 'Avance',
    component: Avance
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout
  },
  {
    path: '/adminSec',
    name: 'AdminSec',
    component: AdminSec
  },
  {
    path: '/user/usrAdmin',
    name: 'UserAdmin',
    component: UserAdmin
  },
  {
    path: '/user/usrAdmin/:action',
    name: 'ActionsAdmin',
    component: ActionsAdmin
  },
  {
    path: '/user/usrAdmin/:action/:id?',
    name: 'ActionsAdmin',
    component: ActionsAdmin
  },


  {
    path: '/user/usrAdmin',
    name: 'UserAdmin',
    component: UserAdmin
  },



  // ***********************cat_meses*********************************
  // Aquí se hace la llamada a la vista a utilizar para esta url
  {
    // Se declara la url a utilizar
    path: '/mes/mesAdmin',
    // Se le asigna un nombre
    name: 'MesAdmin',
    // Se indica el componente a usar ( Se importa al inicio de éste archivo )
    component: MesAdmin
  },
  // Aquí configuro las rutas (url) para las acciones de los meses
  // Solo lo dejo declarado, hay que desarrollar los archivos
  {
    path: '/mes/mesAdmin/:action',
    name: 'ActionsMesesAdmin',
    component: ActionsMesesAdmin
  },
  {
    // :action - inica la accion
    // :id? - parámetro opcional
    path: '/mes/mesAdmin/:action/:id?',
    name: 'ActionsMesesAdmin',
    component: ActionsMesesAdmin
  },
  // ***********************param_gen*********************************
  // Aquí configuro las rutas (url) para las acciones de la Parametros generales de acciones puntuales
  // Solo lo dejo declarado, hay que desarrollar los archivos
  {
    path: '/param_gen/param_genAdmin/:action',
    name: 'ActionsParam_genAdmin',
    component: ActionsParam_genAdmin
  },
  {
    // :action - inica la accion
    // :id? - parámetro opcional
    path: '/param_gen/param_genAdmin/:action/:id?',
    name: 'ActionsParam_genAdmin',
    component: ActionsParam_genAdmin
  },
  // ***********************cat_objetivos*********************************
  // Aquí se hace la llamada a la vista a utilizar para esta url
  {
    // Se declara la url a utilizar
    path: '/objetivo/objetivoAdmin',
    // Se le asigna un nombre
    name: 'ObjetivoAdmin',
    // Se indica el componente a usar ( Se importa al inicio de éste archivo )
    component: ObjetivoAdmin
  },
  // Aquí configuro las rutas (url) para las acciones de los objetivos
  // Solo lo dejo declarado, hay que desarrollar los archivos
  {
    path: '/objetivo/objetivoAdmin/:action',
    name: 'ActionsObjetivosAdmin',
    component: ActionsObjetivosAdmin
  },
  {
    // :action - inica la accion
    // :id? - parámetro opcional
    path: '/objetivo/objetivoAdmin/:action/:id?',
    name: 'ActionsObjetivosAdmin',
    component: ActionsObjetivosAdmin
  },
  // ***********************cat_dependencias*********************************
  // Aquí se hace la llamada a la vista a utilizar para esta url
  {
    // Se declara la url a utilizar
    path: '/dependencia/dependenciaAdmin',
    // Se le asigna un nombre
    name: 'DependenciaAdmin',
    // Se indica el componente a usar ( Se importa al inicio de éste archivo )
    component: DependenciaAdmin
  },
  // Aquí configuro las rutas (url) para las acciones de los dependencias
  // Solo lo dejo declarado, hay que desarrollar los archivos
  {
    path: '/dependencia/dependenciaAdmin/:action',
    name: 'ActionsDependenciasAdmin',
    component: ActionsDependenciasAdmin
  },
  {
    // :action - inica la accion
    // :id? - parámetro opcional
    path: '/dependencia/dependenciaAdmin/:action/:id?',
    name: 'ActionsDependenciasAdmin',
    component: ActionsDependenciasAdmin
  },
  // ***********************cat_estado*********************************
  // Aquí se hace la llamada a la vista a utilizar para esta url
  {
    // Se declara la url a utilizar
    path: '/estado/estadoAdmin',
    // Se le asigna un nombre
    name: 'EstadoAdmin',
    // Se indica el componente a usar ( Se importa al inicio de éste archivo )
    component: EstadoAdmin
  },
  // Aquí configuro las rutas (url) para las acciones de los estados
  // Solo lo dejo declarado, hay que desarrollar los archivos
  {
    path: '/estado/estadoAdmin/:action',
    name: 'ActionsEstadosAdmin',
    component: ActionsEstadosAdmin
  },
  {
    // :action - inica la accion
    // :id? - parámetro opcional
    path: '/estado/estadoAdmin/:action/:id?',
    name: 'ActionsEstadosAdmin',
    component: ActionsEstadosAdmin
  },
// ***********************cat_nivel_desagregacion*********************************
  // Aquí se hace la llamada a la vista a utilizar para esta url
  {
    // Se declara la url a utilizar
    path: '/nivel/nivelAdmin',
    // Se le asigna un nombre
    name: 'NivelAdmin',
    // Se indica el componente a usar ( Se importa al inicio de éste archivo )
    component: NivelAdmin
  },
  // Aquí configuro las rutas (url) para las acciones de los niveles
  // Solo lo dejo declarado, hay que desarrollar los archivos
  {
    path: '/nivel/nivelAdmin/:action',
    name: 'ActionsNivelesAdmin',
    component: ActionsNivelesAdmin
  },
  {
    // :action - inica la accion
    // :id? - parámetro opcional
    path: '/nivel/nivelAdmin/:action/:id?',
    name: 'ActionsNivelesAdmin',
    component: ActionsNivelesAdmin
  },  
// ***********************cat_periodicidad*********************************
  // Aquí se hace la llamada a la vista a utilizar para esta url
  {
    // Se declara la url a utilizar
    path: '/periodicidad/periodicidadAdmin',
    // Se le asigna un nombre
    name: 'PeriodicidadAdmin',
    // Se indica el componente a usar ( Se importa al inicio de éste archivo )
    component: PeriodicidadAdmin
  },
  // Aquí configuro las rutas (url) para las acciones de los periodos
  // Solo lo dejo declarado, hay que desarrollar los archivos
  {
    path: '/periodicidad/periodicidadAdmin/:action',
    name: 'ActionsPeriodicidadesAdmin',
    component: ActionsPeriodicidadesAdmin
  },
  {
    // :action - inica la accion
    // :id? - parámetro opcional
    path: '/periodicidad/periodicidadAdmin/:action/:id?',
    name: 'ActionsPeriodicidadesAdmin',
    component: ActionsPeriodicidadesAdmin
  },
  // ***********************cat_tipo_formula*********************************
  // Aquí se hace la llamada a la vista a utilizar para esta url
  {
    // Se declara la url a utilizar
    path: '/formula/formulaAdmin',
    // Se le asigna un nombre
    name: 'FormulaAdmin',
    // Se indica el componente a usar ( Se importa al inicio de éste archivo )
    component: FormulaAdmin
  },
  // Aquí configuro las rutas (url) para las acciones de las formulas
  // Solo lo dejo declarado, hay que desarrollar los archivos
  {
    path: '/formula/formulaAdmin/:action',
    name: 'ActionsFormulasAdmin',
    component: ActionsFormulasAdmin
  },
  {
    // :action - inica la accion
    // :id? - parámetro opcional
    path: '/formula/formulaAdmin/:action/:id?',
    name: 'ActionsFormulasAdmin',
    component: ActionsFormulasAdmin
  },
  // ***********************cat_anio*********************************
  // Aquí se hace la llamada a la vista a utilizar para esta url
  {
    // Se declara la url a utilizar
    path: '/anio/anioAdmin',
    // Se le asigna un nombre
    name: 'anioAdmin',
    // Se indica el componente a usar ( Se importa al inicio de éste archivo )
    component: AnioAdmin
  },
  // Aquí configuro las rutas (url) para las acciones de los anios
  // Solo lo dejo declarado, hay que desarrollar los archivos
  {
    path: '/anio/anioAdmin/:action',
    name: 'ActionsAniosAdmin',
    component: ActionsAniosAdmin
  },
  {
    // :action - inica la accion
    // :id? - parámetro opcional
    path: '/anio/anioAdmin/:action/:id?',
    name: 'ActionsAniosAdmin',
    component: ActionsAniosAdmin
  },
  // ***********************cat_unidad_medida*********************************
  // Aquí se hace la llamada a la vista a utilizar para esta url
  {
    // Se declara la url a utilizar
    path: '/unidad_medida/unidad_medidaAdmin',
    // Se le asigna un nombre
    name: 'unidad_medidaAdmin',
    // Se indica el componente a usar ( Se importa al inicio de éste archivo )
    component: Unidad_medidaAdmin
  },
  // Aquí configuro las rutas (url) para las acciones de las unidades de medida
  // Solo lo dejo declarado, hay que desarrollar los archivos
  {
    path: '/unidad_medida/unidad_medidaAdmin/:action',
    name: 'ActionsUnidades_medidaAdmin',
    component: ActionsUnidades_medidaAdmin
  },
  {
    // :action - inica la accion
    // :id? - parámetro opcional
    path: '/unidad_medida/unidad_medidaAdmin/:action/:id?',
    name: 'ActionsUnidades_medidaAdmin',
    component: ActionsUnidades_medidaAdmin
  },
  // ***********************cat_clase_ind*********************************
  // Aquí se hace la llamada a la vista a utilizar para esta url
  {
    // Se declara la url a utilizar
    path: '/clase_ind/clase_indAdmin',
    // Se le asigna un nombre
    name: 'clase_indAdmin',
    // Se indica el componente a usar ( Se importa al inicio de éste archivo )
    component: Clase_indAdmin
  },
  // Aquí configuro las rutas (url) para las acciones de las clases de indicadores
  // Solo lo dejo declarado, hay que desarrollar los archivos
  {
    path: '/clase_ind/clase_indAdmin/:action',
    name: 'ActionsClases_indAdmin',
    component: ActionsClases_indAdmin
  },
  {
    // :action - inica la accion
    // :id? - parámetro opcional
    path: '/clase_ind/clase_indAdmin/:action/:id?',
    name: 'ActionsClases_indAdmin',
    component: ActionsClases_indAdmin
  },
  // ***********************cat_dimension_indicador*********************************
  // Aquí se hace la llamada a la vista a utilizar para esta url
  {
    // Se declara la url a utilizar
    path: '/dimension_indicador/dimension_indicadorAdmin',
    // Se le asigna un nombre
    name: 'dimension_indicadorAdmin',
    // Se indica el componente a usar ( Se importa al inicio de éste archivo )
    component: Dimension_indicadorAdmin
  },
  // Aquí configuro las rutas (url) para las acciones de las dimensiones del indicador
  // Solo lo dejo declarado, hay que desarrollar los archivos
  {
    path: '/dimension_indicador/dimension_indicadorAdmin/:action',
    name: 'ActionsDimensiones_indicadorAdmin',
    component: ActionsDimensiones_indicadorAdmin
  },
  {
    // :action - inica la accion
    // :id? - parámetro opcional
    path: '/dimension_indicador/dimension_indicadorAdmin/:action/:id?',
    name: 'ActionsDimensiones_indicadorAdmin',
    component: ActionsDimensiones_indicadorAdmin
  },
  // ***********************cat_periodo_recoleccion*********************************
  // Aquí se hace la llamada a la vista a utilizar para esta url
  {
    // Se declara la url a utilizar
    path: '/periodo_recoleccion/periodo_recoleccionAdmin',
    // Se le asigna un nombre
    name: 'periodo_recoleccionAdmin',
    // Se indica el componente a usar ( Se importa al inicio de éste archivo )
    component: Periodo_recoleccionAdmin
  },
  // Aquí configuro las rutas (url) para las acciones de las unidades de medida
  // Solo lo dejo declarado, hay que desarrollar los archivos
  {
    path: '/periodo_recoleccion/periodo_recoleccionAdmin/:action',
    name: 'ActionsPeriodos_recoleccionAdmin',
    component: ActionsPeriodos_recoleccionAdmin
  },
  {
    // :action - inica la accion
    // :id? - parámetro opcional
    path: '/periodo_recoleccion/periodo_recoleccionAdmin/:action/:id?',
    name: 'ActionsPeriodos_recoleccionAdmin',
    component: ActionsPeriodos_recoleccionAdmin
  },
  // ***********************cat_tipo_calculo*********************************
  // Aquí se hace la llamada a la vista a utilizar para esta url
  {
    // Se declara la url a utilizar
    path: '/tipo_calculo/tipo_calculoAdmin',
    // Se le asigna un nombre
    name: 'tipo_calculoAdmin',
    // Se indica el componente a usar ( Se importa al inicio de éste archivo )
    component: Tipo_calculoAdmin
  },
  // Aquí configuro las rutas (url) para las acciones de las tipo de cálculo
  // Solo lo dejo declarado, hay que desarrollar los archivos
  {
    path: '/tipo_calculo/tipo_calculoAdmin/:action',
    name: 'ActionsTipos_calculoAdmin',
    component: ActionsTipos_calculoAdmin
  },
  {
    // :action - inica la accion
    // :id? - parámetro opcional
    path: '/tipo_calculo/tipo_calculoAdmin/:action/:id?',
    name: 'ActionsTipos_calculoAdmin',
    component: ActionsTipos_calculoAdmin
  },    
  // ***********************cat_tendencia_indicador*********************************
  // Aquí se hace la llamada a la vista a utilizar para esta url
  {
    // Se declara la url a utilizar
    path: '/tendencia_indicador/tendencia_indicadorAdmin',
    // Se le asigna un nombre
    name: 'tendencia_indicadorAdmin',
    // Se indica el componente a usar ( Se importa al inicio de éste archivo )
    component: Tendencia_indicadorAdmin
  },
  // Aquí configuro las rutas (url) para las acciones de las tendencias del indicador
  // Solo lo dejo declarado, hay que desarrollar los archivos
  {
    path: '/tendencia_indicador/tendencia_indicadorAdmin/:action',
    name: 'ActionsTendencias_indicadorAdmin',
    component: ActionsTendencias_indicadorAdmin
  },
  {
    // :action - inica la accion
    // :id? - parámetro opcional
    path: '/tendencia_indicador/tendencia_indicadorAdmin/:action/:id?',
    name: 'ActionsTendencias_indicadorAdmin',
    component: ActionsTendencias_indicadorAdmin
  },
  // ***********************cat_tipo_ap*********************************
  // Aquí se hace la llamada a la vista a utilizar para esta url
  {
    // Se declara la url a utilizar
    path: '/tipo_ap/tipo_apAdmin',
    // Se le asigna un nombre
    name: 'tipo_apAdmin',
    // Se indica el componente a usar ( Se importa al inicio de éste archivo )
    component: Tipo_apAdmin
  },
  // Aquí configuro las rutas (url) para las acciones de las unidades de medida
  // Solo lo dejo declarado, hay que desarrollar los archivos
  {
    path: '/tipo_ap/tipo_apAdmin/:action',
    name: 'ActionsTipos_apAdmin',
    component: ActionsTipos_apAdmin
  },
  {
    // :action - inica la accion
    // :id? - parámetro opcional
    path: '/tipo_ap/tipo_apAdmin/:action/:id?',
    name: 'ActionsTipos_apAdmin',
    component: ActionsTipos_apAdmin
  },
  // ***********************cat_tipo_indicador*********************************
  // Aquí se hace la llamada a la vista a utilizar para esta url
  {
    // Se declara la url a utilizar
    path: '/tipo_indicador/tipo_indicadorAdmin',
    // Se le asigna un nombre
    name: 'tipo_indicadorAdmin',
    // Se indica el componente a usar ( Se importa al inicio de éste archivo )
    component: Tipo_indicadorAdmin
  },
  // Aquí configuro las rutas (url) para las acciones de los tipos de indicador
  // Solo lo dejo declarado, hay que desarrollar los archivos
  {
    path: '/tipo_indicador/tipo_indicadorAdmin/:action',
    name: 'ActionsTipos_indicadorAdmin',
    component: ActionsTipos_indicadorAdmin
  },
  {
    // :action - inica la accion
    // :id? - parámetro opcional
    path: '/tipo_indicador/tipo_indicadorAdmin/:action/:id?',
    name: 'ActionsTipos_indicadorAdmin',
    component: ActionsTipos_indicadorAdmin
  },
  // ***********************cat_estrategia*********************************
  // Aquí se hace la llamada a la vista a utilizar para esta url
  {
    // Se declara la url a utilizar
    path: '/estrategia/estrategiaAdmin',
    // Se le asigna un nombre
    name: 'estrategiaAdmin',
    // Se indica el componente a usar ( Se importa al inicio de éste archivo )
    component: EstrategiaAdmin
  },
  // Aquí configuro las rutas (url) para las acciones del catálogo de estrategias
  // Solo lo dejo declarado, hay que desarrollar los archivos
  {
    path: '/estrategia/estrategiaAdmin/:action',
    name: 'ActionsEstrategiasAdmin',
    component: ActionsEstrategiasAdmin
  },
  {
    // :action - inica la accion
    // :id? - parámetro opcional
    path: '/estrategia/estrategiaAdmin/:action/:id?',
    name: 'ActionsEstrategiasAdmin',
    component: ActionsEstrategiasAdmin
  },
  // ***********************cat_bienvenida*********************************
  // Aquí configuro las rutas (url) para las acciones de la bienvenida
  // Solo lo dejo declarado, hay que desarrollar los archivos
  {
    path: '/bienvenida/bienvenidaAdmin/:action',
    name: 'ActionsBienvenidasAdmin',
    component: ActionsBienvenidasAdmin
  },
  {
    // :action - inica la accion
    // :id? - parámetro opcional
    path: '/bienvenida/bienvenidaAdmin/:action/:id?',
    name: 'ActionsBienvenidasAdmin',
    component: ActionsBienvenidasAdmin
  },
  // ***********************cat_projuventud*********************************
  // Aquí configuro las rutas (url) para las acciones de projuventud
  // Solo lo dejo declarado, hay que desarrollar los archivos
  {
    path: '/projuventud/projuventudAdmin/:action',
    name: 'ActionsProjuventudesAdmin',
    component: ActionsProjuventudesAdmin
  },
  {
    // :action - inica la accion
    // :id? - parámetro opcional
    path: '/projuventud/projuventudAdmin/:action/:id?',
    name: 'ActionsProjuventudesAdmin',
    component: ActionsProjuventudesAdmin
  },
  // ***********************presup_juventud*********************************
  // Aquí configuro las rutas (url) para las acciones de presup_juventud
  // Solo lo dejo declarado, hay que desarrollar los archivos
  {
    path: '/presupuesto/presupuestoAdmin/:action',
    name: 'ActionsPresupuestosAdmin',
    component: ActionsPresupuestosAdmin
  },
  {
    // :action - inica la accion
    // :id? - parámetro opcional
    path: '/presupuesto/presupuestoAdmin/:action/:id?',
    name: 'ActionsPresupuestosAdmin',
    component: ActionsPresupuestosAdmin
  },
      // ***********************Intervenciones***ADMIN**************************
  // Aquí configuro las rutas (url) para las acciones de intervenciones
  // Solo lo dejo declarado, hay que desarrollar los archivos
  {
    path: '/intervenciones/intervencionesAdm/:action',
    name: 'ActionsIntervencionesAdm',
    component: ActionsIntervencionesAdm
  },
  {
    // :action - inica la accion
    // :id? - parámetro opcional
    path: '/intervenciones/intervencionesAdm/:action/:id?',
    name: 'ActionsIntervencionesAdm',
    component: ActionsIntervencionesAdm
  },
  // ***********************meta_ficha*********************************
  // Aquí se hace la llamada a la vista a utilizar para esta url
  {
    // Se declara la url a utilizar
    path: '/meta_ficha/meta_fichaAdmin',
    // Se le asigna un nombre
    name: 'meta_fichaAdmin',
    // Se indica el componente a usar ( Se importa al inicio de éste archivo )
    component: Meta_fichaAdmin
  },
  // Aquí configuro las rutas (url) para las acciones del catálogo de meta_fichas
  // Solo lo dejo declarado, hay que desarrollar los archivos
  {
    path: '/meta_ficha/meta_fichaAdmin/:action',
    name: 'ActionsMetas_fichaAdmin',
    component: ActionsMetas_fichaAdmin
  },
  {
    // :action - inica la accion
    // :id? - parámetro opcional
    path: '/meta_ficha/meta_fichaAdmin/:action/:id?',
    name: 'ActionsMetas_fichaAdmin',
    component: ActionsMetas_fichaAdmin
  },
  // ***********************accion_puntual*********************************
  // Aquí se hace la llamada a la vista a utilizar para esta url
  {
    // Se declara la url a utilizar
    path: '/accion_puntual/accion_puntualAdmin',
    // Se le asigna un nombre
    name: 'accion_puntualAdmin',
    // Se indica el componente a usar ( Se importa al inicio de éste archivo )
    component: Accion_puntualAdmin
  },
  // Aquí configuro las rutas (url) para las acciones del catálogo de accion_puntual
  // Solo lo dejo declarado, hay que desarrollar los archivos
  {
    path: '/accion_puntual/accion_puntualAdmin/:action',
    name: 'ActionsAccions_puntualAdmin',
    component: ActionsAccions_puntualAdmin
  },
  {
    // :action - inica la accion
    // :id? - parámetro opcional
    path: '/accion_puntual/accion_puntualAdmin/:action/:id?',
    name: 'ActionsAccions_puntualAdmin',
    component: ActionsAccions_puntualAdmin
  },
    // ***********************accion_sectorial*********************************
  // Aquí se hace la llamada a la vista a utilizar para esta url
  {
    // Se declara la url a utilizar
    path: '/accion_sectorial/accion_sectorialAdmin',
    // Se le asigna un nombre
    name: 'accion_sectorialAdmin',
    // Se indica el componente a usar ( Se importa al inicio de éste archivo )
    component: Accion_sectorialAdmin
  },
  // Aquí configuro las rutas (url) para las acciones del catálogo de accion_sectorial
  // Solo lo dejo declarado, hay que desarrollar los archivos
  {
    path: '/accion_sectorial/accion_sectorialAdmin/:action',
    name: 'ActionsAcciones_sectorialAdmin',
    component: ActionsAcciones_sectorialAdmin
  },
  {
    // :action - inica la accion
    // :id? - parámetro opcional
    path: '/accion_sectorial/accion_sectorialAdmin/:action/:id?',
    name: 'ActionsAcciones_sectorialAdmin',
    component: ActionsAcciones_sectorialAdmin
  },

  // ***********************informes*********************************
  // Aquí se hace la llamada a la vista a utilizar para esta url
  {
    // Se declara la url a utilizar
    path: '/informe/informeAdmin',
    // Se le asigna un nombre
    name: 'informeAdmin',
    // Se indica el componente a usar ( Se importa al inicio de éste archivo )
    component: InformeAdmin
  },
  // Aquí configuro las rutas (url) para las acciones de la informe
  // Solo lo dejo declarado, hay que desarrollar los archivos
  {
    path: '/informe/informeAdmin/:action',
    name: 'ActionsInformesAdmin',
    component: ActionsInformesAdmin
  },
  {
    // :action - inica la accion
    // :id? - parámetro opcional
    path: '/informe/informeAdmin/:action/:id?',
    name: 'ActionsInformesAdmin',
    component: ActionsInformesAdmin
  },
    // ***********************Compromiso_dep*********************************
  // Aquí se hace la llamada a la vista a utilizar para esta url
  {
    // Se declara la url a utilizar
    path: '/compromiso_dep/compromiso_depAdmin',
    // Se le asigna un nombre
    name: 'compromiso_depAdmin',
    // Se indica el componente a usar ( Se importa al inicio de éste archivo )
    component: Compromiso_depAdmin
  },
  // Aquí configuro las rutas (url) para las acciones de la compromiso_dep
  // Solo lo dejo declarado, hay que desarrollar los archivos
  {
    path: '/compromiso_dep/compromiso_depAdmin/:action',
    name: 'ActionsCompromisos_depAdmin',
    component: ActionsCompromisos_depAdmin
  },
  {
    // :action - inica la accion
    // :id? - parámetro opcional
    path: '/compromiso_dep/compromiso_depAdmin/:action/:id?',
    name: 'ActionsCompromisos_depAdmin',
    component: ActionsCompromisos_depAdmin
  },
  // ***********************descargables*********************************
  // Aquí se hace la llamada a la vista a utilizar para esta url
  {
    // Se declara la url a utilizar
    path: '/descargable/descargableAdmin',
    // Se le asigna un nombre
    name: 'descargableAdmin',
    // Se indica el componente a usar ( Se importa al inicio de éste archivo )
    component: DescargableAdmin
  },
  // Aquí configuro las rutas (url) para las acciones de los descargables
  // Solo lo dejo declarado, hay que desarrollar los archivos
  {
    path: '/descargable/descargableAdmin/:action',
    name: 'ActionsDescargablesAdmin',
    component: ActionsDescargablesAdmin
  },
  {
    // :action - inica la accion
    // :id? - parámetro opcional
    path: '/descargable/descargableAdmin/:action/:id?',
    name: 'ActionsDescargablesAdmin',
    component: ActionsDescargablesAdmin
  },


    // ***********************accion_puntual_objetivo_avance*********************************
  // Aquí se hace la llamada a la vista a utilizar para esta url
  {
    // Se declara la url a utilizar
    path: '/accion_objetivo_av/accion_objetivo_avAdmin',
    // Se le asigna un nombre
    name: 'accion_objetivo_avAdmin',
    // Se indica el componente a usar ( Se importa al inicio de éste archivo )
    component: Accion_objetivo_avAdmin
  },
  // Aquí configuro las rutas (url) para las acciones del catálogo de accion_objetivo_av
  // Solo lo dejo declarado, hay que desarrollar los archivos
  {
    path: '/accion_objetivo_av/accion_objetivo_avAdmin/:action',
    name: 'ActionsAccions_objetivo_avAdmin',
    component: ActionsAccions_objetivo_avAdmin
  },
  {
    // :action - inica la accion
    // :id? - parámetro opcional
    path: '/accion_objetivo_av/accion_objetivo_avAdmin/:action/:id?',
    name: 'ActionsAccions_objetivo_avAdmin',
    component: ActionsAccions_objetivo_avAdmin
  },
  // ***********************intervenciones en Juventud*********************************
  // Aquí se hace la llamada a la vista a utilizar para esta url
  {
    // Se declara la url a utilizar
    path: '/intervenciones/intervencionesAdmin',
    // Se le asigna un nombre
    name: 'intervencionesAdmin',
    // Se indica el componente a usar ( Se importa al inicio de éste archivo )
    component: IntervencionesAdmin
  },
  {
    // Se declara la url a utilizar
    path: '/intervenciones/intervencionesOrder',
    // Se le asigna un nombre
    name: 'intervencionesOrder',
    // Se indica el componente a usar ( Se importa al inicio de éste archivo )
    component: IntervencionesOrder
  },
  // Aquí configuro las rutas (url) para las acciones del catálogo de intervenciones
  // Solo lo dejo declarado, hay que desarrollar los archivos
  {
    path: '/intervenciones/intervencionesAdmin/:action',
    name: 'ActionsIntervencionesAdmin',
    component: ActionsIntervencionesAdmin
  },
  {
    // :action - inica la accion
    // :id? - parámetro opcional
    path: '/intervenciones/intervencionesAdmin/:action/:id?',
    name: 'ActionsIntervencionesAdmin',
    component: ActionsIntervencionesAdmin
  },
  
  {
    path: '/recover',
    name: 'RecuperaciónPassword',
    component: Recover
  },
  {
    // :hash - parámetro
    path: '/cambio_password/:hash',
    name: 'CambioPassword',
    component: CambioPassword
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const secName = to.name?.toString()
  document.title = `${ process.env.VUE_APP_TITLE } - ` + secName
  if (to.params.title) {
    document.title = `${ process.env.VUE_APP_TITLE } - ${ secName } - ${ to.params.title }`
  }
  next()
})

export default router
