<template>
    <div class="mainDiv">
        <Navbar />
        <div class="mt-5 container principal text-left">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="http://www.gob.mx/"><i class="icon icon-home"></i></a></li>
                <li class="breadcrumb-item"><a href="https://www.gob.mx/imjuve">IMJUVE</a></li>
                <li class="breadcrumb-item"><a href="/">SIMEJUV</a></li>
                <li class="breadcrumb-item active" aria-current="page"><a href="/intervenciones">CATALOGO DE OFERTA PARA LAS Y LOS JOVENES</a></li>
            </ol>
            <h2>{{this.interv_titulo}}</h2>
            <div class="card-header" >
                <span><strong><p class="card-text" v-html="this.interv_cont_tit"></p></strong></span>
            </div>
            <hr class="red">
            <!-- <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist" :class="{hide1:detalle1||detalle2}"> -->
            <ul class="nav nav-pills">
                <li role="presentation" :class="{ activeNav:interv_reg }"><router-link class="flex-sm-fill text-center nav-link" :class="{ active:interv_reg }" :to="{ params: {mod: 'interv_reg', title: 'Registro' } }">Registro</router-link></li>                
                <li role="presentation" :class="{ activeNav:ver }"><router-link class="flex-sm-fill text-center nav-link" :class="{ active:ver }" :to="{ params: {mod: 'ver', title: 'Ver ofertas' } }">Ver ofertas</router-link></li>
                <li role="presentation" :class="{ activeNav:reportes }"><router-link class="flex-sm-fill text-center nav-link" :class="{ active:reportes }" :to="{ params: {mod: 'reportes', title: 'Estadísticas' } }">Estadísticas</router-link></li>
            </ul>
            <div class="panel panel-default">
                <div class="panel-body">
                    <Interv_reg v-if="interv_reg" />
                    <Ver v-if="ver" />
                    <Reportes v-if="reportes" />
                </div>
            </div>            
        </div>
    </div>
</template>

<script>
import Navbar from '@/components/Common/Navbar'
import Interv_reg from '@/components/Intervenciones/Interv_reg';
import Ver from '@/components/Intervenciones/Ver';
import Reportes from '@/components/Intervenciones/Reportes';
import getIntervParam from '@/helpers/getIntervParam';

export default {
    components: {
        Navbar,
        Interv_reg,
        Ver,
        Reportes
    },
    data() {
        return {
            interv_titulo: '',
            interv_cont_tit: '',
            interv_reg: false,
            ver: false,
            reportes: false
        }
    },
    methods: {
        resetAll() {
            this.interv_reg = false
            this.ver = false
            this.reportes = false
        },
        activeTab() {
            if ( this.$route.params.mod === 'interv_reg' ) {
                this.resetAll()
                this.interv_reg = true
            } else if ( this.$route.params.mod === 'ver' ) {
                this.resetAll()
                this.ver = true
            } else if ( this.$route.params.mod === 'reportes' ) {
                this.resetAll()
                this.reportes = true
            }
        },
        async getInterv_param() {
            const dataInterv_param = await getIntervParam();
            this.interv_titulo = `${ dataInterv_param[0].interv_titulo } `;
            this.interv_cont_tit = `${ dataInterv_param[0].interv_cont_tit } `;
            return
        },
    },
    

    async mounted() {
        await this.getInterv_param();
        this.activeTab()
        console.log();
        window.scrollTo(0, 0)
    },
    beforeUpdate() {
        this.activeTab()
    }
}
</script>

<style scoped>
    .nav>li, .nav>li>a {
        position: inherit;
    }

    .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
        background-color: #9D2449;
    }

    a.nav-link {
        text-decoration: none;
        background-color: #98989A;
        border-color: #98989A;
        color: #FFF;
    }

    a.nav-link:hover {
        text-decoration: none;
        background-color: #6F7271;
        border-color: #6F7271;
        color: #FFF;
    }

    .nav>li>a:focus, .nav>li>a:hover {
        text-decoration: none;
        background-color: #6F7271;
        border-color: #6F7271;
        color: #FFF;
    }

    .activeNav {
        background-color: #9D2449;
    }
</style>