import { ref } from 'vue'
//export const url = ref( 'http://localhost' )
//export const ubApi = ref( 'http://localhost/ubApi' )
//export const icons = ref( 'http://localhost/icons' ) 

//export const url = ref( 'https://simejuv.unobinario.com' )
//export const ubApi = ref( 'https://simejuv.unobinario.com/ubApi' )
//export const icons = ref( 'https://simejuv.unobinario.com/icons' )

export const url = ref( 'https://pruebas.unobinario.com' )
export const ubApi = ref( 'https://pruebas.unobinario.com/ubApi' )
export const icons = ref( 'https://pruebas.unobinario.com/icons' )

//export const url = ref( 'https://simejuv.imjuventud.gob.mx' )
//export const ubApi = ref( 'https://simejuv.imjuventud.gob.mx/ubApi' )
//export const icons = ref( 'https://simejuv.imjuventud.gob.mx/icons' ) 

