import axios from 'axios'

const simejuvApi = axios.create({
    //baseURL: 'http://localhost/ubApi/index.php',
    //baseURL: 'https://simejuv.unobinario.com/ubApi/index.php',
    baseURL: 'https://pruebas.unobinario.com/ubApi/index.php',
    //baseURL: 'https://simejuv.imjuventud.gob.mx/ubApi/index.php',
    
    headers: {
        "Content-type": "application/json"
    }
})

export default simejuvApi