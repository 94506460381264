import axios from 'axios'

const baseApi = axios.create({
    //baseURL: 'http://localhost/ubApi',
    //baseURL: 'https://simejuv.unobinario.com/ubApi',
    baseURL: 'https://pruebas.unobinario.com/ubApi',
    //baseURL: 'https://simejuv.imjuventud.gob.mx/ubApi',

    headers: {
        "Content-type": "application/json"
    }
})

export default baseApi