<template>
    <div class="mainDiv">
        <Navbar />
        <div class="mt-5 container principal text-left">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="http://www.gob.mx/"><i class="icon icon-home"></i></a></li>
                <li class="breadcrumb-item"><a href="https://www.gob.mx/imjuve">IMJUVE</a></li>
                <li class="breadcrumb-item"><a href="/">SIMEJUV</a></li>
                <li class="breadcrumb-item active" aria-current="page"><a href="/aten_ciud">PARTICIPACIÓN CIUDADANA</a></li>
            </ol>
            <h2>Participación Ciudadana</h2>

            <hr class="red">

            <div  v-for="e in datosPresup_juventud" :key="e.id">
                <h3>Nuestros mecanismos de Participación</h3>
            </div>

            <div class="container mb-5">
                <p>Busca incorporar a las personas jóvenes de forma activa en el diseño y elaboración de políticas públicas. Permite identificar y deliberar en conjunto acerca de problemas públicos y sus soluciones, con metodologías y herramientas que fomentan la creación de espacios de reflexión y diálogo colectivo.</p>
            </div>

            <div v-show="this.muestra_btn_consepp"> 
                <ul class="nav nav-pills">
                    <p class="card-text" >Seleccione una opción: </p>
                    <li role="presentation" :class="{ activeNav:consepp }"><router-link class="flex-sm-fill text-center nav-link" :class="{ active:consepp }" :to="{ params: {mod: 'consepp', title: 'CONSEPP' } }">CONSEPP</router-link></li>
                    <!-- <li role="presentation" :class="{ activeNav:consepp }"><router-link class="flex-sm-fill text-center nav-link" :class="{ active:consepp }" :to="{ params: {mod: 'consepp', title: 'CONSEPP' } }">Participación joven</router-link></li> /> --> 
                    <!-- <li role="presentation" :class="{ activeNav:consepp }"><a href="" target="_blank" class="button flex-sm-fill text-center nav-link">Participación joven</a></li> /> -->
                </ul>
                <div class="row">
                    <div class="col-md-2">
                        
                    </div>
                </div>
            </div>          
            <br><br><br><br>
        </div>
    </div>
</template>

<script>

import Navbar from '@/components/Common/Navbar'

export default {
    components: {
        Navbar
    },
    data() {
        return {
            muestra_btn_consepp:true,
        }
    },
    methods: {
        activeTab() {
            if ( this.$route.params.mod === 'consepp' ) {
                this.resetAll()
                //this.consepp = true
                this.muestra_btn_consepp=false
            }
        },
    },
    async mounted() {
        this.activeTab()
        window.scrollTo(0, 0)
    },
    beforeUpdate() {
        this.activeTab()
    } 
}

</script>
<style scoped>
    .nav>li, .nav>li>a {
        position: inherit;
    }

    .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
        background-color: #9D2449;
    }

    a.nav-link {
        text-decoration: none;
        background-color: #98989A;
        border-color: #98989A;
        color: #FFF;
    }

    a.nav-link:hover {
        text-decoration: none;
        background-color: #6F7271;
        border-color: #6F7271;
        color: #FFF;
    }

    .nav>li>a:focus, .nav>li>a:hover {
        text-decoration: none;
        background-color: #6F7271;
        border-color: #6F7271;
        color: #FFF;
    }

    .activeNav {
        background-color: #9D2449;
    }
    
    
    
    
    
    .subTitle {
        font-weight: normal;
        padding: 15px;
    }
    .article-body {
        padding: 15px;
    }

    .article-body>p {
        padding: 10px 0;
    }

    strong {
        color: #235B4E;
    }
    .article-body {
        text-align: justify;
    }
</style>